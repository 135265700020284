import { useState } from 'react'
import { activateAccount } from '~/src/services/CustomerService'
import { useRouterPush } from '~/src/hooks/router/useRouterPush'
import { useFirmPathResolver } from '~/src/hooks/router/useFirmPathResolver'
import RoutesEnum from '~/src/router/enums/routes.enum'
import { useSnackbar } from 'notistack'
import { useTranslations } from '~/src/hooks/useTranslations'
import AccountActivationSuccessDialog from '~/src/components/auth/account-activation/AccountActivationSuccessDialog'
import { DialogNameEnum } from '~/src/types/DialogNameEnum'
import useDialog from '~/src/hooks/useDialog'

interface ReturnsType {
  activateAccount(token: string, validity: string, showDialog?: boolean): void,

  error: boolean,
  loading: boolean,
}

const useActivateAccount = (): ReturnsType => {
  const { t } = useTranslations()
  const { enqueueSnackbar } = useSnackbar()
  const { push } = useRouterPush()
  const { resolve } = useFirmPathResolver()
  const [ error, setError ] = useState<boolean>(false)
  const [ loading, setLoading ] = useState<boolean>(false)
  const { present } = useDialog()

  const action = async (token: string, validity: string, showDialog = true) => {
    setError(false)
    setLoading(true)

    activateAccount(token, validity)
      .then(() => {
        showDialog && present(AccountActivationSuccessDialog, DialogNameEnum.ACCOUNT_ACTIVATION_SUCCESS)
        push(resolve(RoutesEnum.LOGIN))
      })
      .catch(() => {
        setError(true)
        enqueueSnackbar(t('errors.defaultError'), { variant: 'error' })
      })
      .finally(() => setLoading(false))
  }

  return { activateAccount: action, error, loading }
}

export {
  useActivateAccount
}
