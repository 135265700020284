import { useTranslations } from '~/src/hooks/useTranslations'
import { Button, dialogContentClasses, Divider, Typography, useTheme } from '@mui/material'
import useDialog from '~/src/hooks/useDialog'
import ResponsiveDialog from '~/src/components/mui-wrappers/responsive-dialog'
import { DialogNameEnum } from '~/src/types/DialogNameEnum'
import Title from '~/src/components/mui-wrappers/Title'
import CssUtils from '~/src/utils/CssUtils'
import { Player } from '@lottiefiles/react-lottie-player'

const AccountActivationSuccessDialog = () => {
  const { t } = useTranslations()
  const theme = useTheme()
  const { dismiss } = useDialog()

  const _handleOnClick = () => {
    dismiss(DialogNameEnum.ACCOUNT_ACTIVATION_SUCCESS)
  }

  return (
    <ResponsiveDialog
      openByDefault
      showCloseButton
      name={DialogNameEnum.ACCOUNT_ACTIVATION_SUCCESS}
      Title={<Title
        variant='h4'
        sx={{
          [theme.breakpoints.up('md')]: {
            visibility: 'hidden'
          }
        }}>
        {t('auth.activateAccount.dialogs.success.title')}
      </Title>
      }
      sx={{
        [`.${dialogContentClasses.root}`]: {
          pb: 4,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          gap: 2.5,
          textAlign: 'center',
          [theme.breakpoints.down('md')]: {
            pb: '10vh'
          },
          [theme.breakpoints.down('sm')]: {
            pb: '5vh'
          }
        }
      }}>
      <Typography variant='h6'>
        {t('auth.activateAccount.dialogs.success.subTitle')}
      </Typography>

      <Player
        src='/images/lotties/validated.json'
        autoplay
        keepLastFrame
        style={{
          width: '60%'
        }}
      />

      <Divider
        sx={{
          width: '50%',
          borderWidth: '2px',
          borderColor: CssUtils.addTransparencyToHexColor(theme.palette.primary.main, 20)
        }}/>

      <Button
        variant='contained'
        color='primary'
        onClick={_handleOnClick}>
        {t('auth.activateAccount.dialogs.success.cta')}
      </Button>
    </ResponsiveDialog>
  )
}

export default AccountActivationSuccessDialog
