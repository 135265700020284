import DocumentTitleInjector from '~/src/components/root/DocumentTitleInjector'
import RoutesEnum from '~/src/router/enums/routes.enum'
import SlideFromRight from '~/src/components/mui-wrappers/animations/motion/SlideFromRight'
import { Box } from '@mui/material'
import AccountActivationLoader from '~/src/components/loader/AccountActivationLoader'
import { useActivateAccount } from '~/src/hooks/customer/useActivateAccount'
import { useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'

const ActivateAccount = () => {
  const pageName: keyof typeof RoutesEnum = 'ACTIVATE_ACCOUNT'
  const [ showRetryButton, setShowRetryButton ] = useState<boolean>(false)
  const [ searchParams ] = useSearchParams()
  const token = searchParams.get('token')
  const timestamp = searchParams.get('timestamp')
  const { activateAccount, error } = useActivateAccount()

  const _activateAccount = () => {
    if (!token || !timestamp) {
      return
    }

    activateAccount(decodeURIComponent(token), decodeURIComponent(timestamp))
  }

  useEffect(() => {
    const timeout = setTimeout(() => {
      _activateAccount()
    }, 2500)

    return () => {
      clearTimeout(timeout)
    }
  }, [])

  useEffect(() => {
    setShowRetryButton(error)
  }, [ error ])

  return (
    <SlideFromRight
      pathname={pageName}
      transition={{
        duration: .5
      }}
    >
      <DocumentTitleInjector pageName={pageName}/>

      <Box
        sx={{
          visibility: 'initial',
          position: 'fixed',
          top: 0,
          left: 0,
          width: '100vw',
          height: '100vh',
          display: 'flex',
          justifyContent: 'center',
          flexDirection: 'column',
          alignItems: 'center',
          gap: 5,
          textAlign: 'center',
          px: 1
        }}
      >
        <AccountActivationLoader
          showRetryButton={showRetryButton}
          retryOnClick={_activateAccount}
        />
      </Box>
    </SlideFromRight>
  )
}

export default ActivateAccount
