import AppLoader from '~/src/components/loader/AppLoader'
import Title from '~/src/components/mui-wrappers/Title'
import { useTranslations } from '~/src/hooks/useTranslations'
import { Button } from '@mui/material'

interface Props {
  showRetryButton: boolean,
  retryOnClick(): void,
}

const AccountActivationLoader = ({ showRetryButton, retryOnClick }: Props) => {
  const { t } = useTranslations()

  return (
    <>
      <AppLoader/>
      <Title component='p'>{t('auth.activateAccount.loader')}</Title>
      {showRetryButton && <Button onClick={retryOnClick}>{t('global.retry')}</Button>}
    </>
  )
}

export default AccountActivationLoader
